<template>
    <v-container class="fill-height">
        <v-row align="center">
            <v-col
                sm="12" offset-sm="0"
                md="6" offset-md="1"
                lg="5" offset-lg="2"
                class="white--text"
            >
                <v-row><span class="text-h3">LearnSpirit</span></v-row>
                <v-row><span class="text-h4 mb-4">L'outil des formateurs</span></v-row>
                <v-row style="line-height: 25pt">Concevoir des formations n’a jamais été aussi simple. Laissez-vous guider et écrivez vos formations avec un découpage idéal de vos objectifs pédagogiques.</v-row>

                <v-divider class="mt-5 mb-7"></v-divider>
            </v-col>
            <v-col sm="12" md="5" lg="5">
                <v-card class="px-9 mx-4 rounded-lg">
                    <v-card-title class="justify-center text-h4">Mot de passe perdu</v-card-title>
                    <v-text-field
                        class="rounded-l-xl"
                        outlined
                        dense
                        v-model="emailForm"
                        placeholder="Adresse email"
                        append-icon="far fa-user"
                        v-if="!token && !email"
                    ></v-text-field>
                    <v-text-field
                        class="rounded-l-xl"
                        v-model="passwordForm"
                        outlined
                        dense
                        placeholder="Nouveau mot de passe"
                        append-icon="fas fa-lock"
                        type="password"
                        v-else
                    ></v-text-field>
                    <v-btn
                        rounded
                        block
                        elevation="0"
                        class="black white--text mb-2"
                        @click="changePassword"
                    >Réinitialiser</v-btn>
                    <v-card-subtitle class="justify-center text-center"><router-link :to="{ name: 'Login', query: $route.query }" class="red--text text-decoration-none" >Retour à la page de connexion</router-link></v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
const { mapActions } = require("vuex");

export default {
    name: "Forgot",

    data: () => ({
        emailForm: "",
        passwordForm: "",
    }),

    created() {
        if (this.$store.state.settings.user) {
            this.$router.push({ name: "FormationsList" });
        }
    },
    computed: {
        email: function() {
            return this.$route.query.email;
        },

        token: function() {
            return this.$route.query.token;
        },
    },

    methods: {
        ...mapActions("global", ["addAlert"]),

        changePassword: function() {
            if(this.email && this.token) {
                this.$store.dispatch("settings/resetPassword", { email: this.email, password: this.passwordForm, token: this.token }).then(
                    () => {
                        this.$router.push({ name: "Login" });

                        alert.type = "success";
                        alert.title = "Mot de passe changé.";
                        alert.message = "Votre mot de passe à été correctement modifié.";
                        alert.duration = 10000;

                        this.addAlert(alert);

                        this.disabled = false;
                    },
                    () => {
                        alert.type = "error";
                        alert.title = "Une erreur est survenue.";
                        alert.message = "Merci de vérifier les informations rentrées.";
                        alert.duration = 10000;

                        this.addAlert(alert);
                        this.disabled = false;
                    },
                );
            } else {
                this.$store.dispatch("settings/askResetPassword", { email: this.emailForm }).then(
                    () => {
                        alert.type = "success";
                        alert.title = "Demande de changement de mot de passe effectuée.";
                        alert.message = "Vérifiez vos emails afin de changer votre mot de passe.";
                        alert.duration = 10000;

                        this.addAlert(alert);
                        this.disabled = false;
                    }
                );
            }
        }
    },


};
</script>

<style scoped>

</style>
